<template>
  <Layout>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Launch New Incident by Address</h4>
            <div class="d-flex ">
              <!-- Launch New Incident from Address-->
              <form>
                <div class="form-group">
                  <label for="launch-name">Incident Name
                  <input type="text" class="form-control mb-4 launch-input"
                         id="launch-name" v-model="launchData.name"
                         placeholder="Enter Incident Name">
                  </label>
                </div>
                <div class="form-group">
                  <label for="launch-location">Incident Location
                  <input type="text" class="form-control mb-4 launch-input"
                         id="launch-location" v-model="launchData.location" ref="location"
                         placeholder="Enter Incident Location">
                  </label>
                </div>
                <b-button class="btn-label" variant="primary" @click="launchFromData(launchData)">
                  <i class="bx bxs-rocket label-icon"></i>Launch from Address
                </b-button>
              </form>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Launch New Incident from Preplan</h4>

            <div class="table-responsive mb-0">
              <!-- TODO Make table sortable for all fields     -->

              <div class="row mb-3">
                <div class="col-6">
                  <p v-if="incidents_count">
                    Showing 1 - {{ incidents_count }} out of {{ incidents_count }}.
                  </p>
                </div>
                <div class="col-6 float-right btn-toolbar">
                  <div class="input-group">
                    <div class="input-group-text" id="search-icon">
                      <i class="bx bx-search"></i></div>
                    <input type="text" class="form-control search-input" v-model="searchInput"
                           placeholder="Search..." @input="filterIncidents"
                           aria-label="Search Input" aria-describedby="search-icon">
                  </div>
                </div>
              </div>
              <table class="table align-middle table-nowrap table-hover">
                <thead class="table-light">
                <tr>
                  <th class="align-middle">Name</th>
                  <th class="align-middle">Location</th>
                  <th class="align-middle">Date/Time</th>
                  <th class="align-middle">Quick Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="incident in filtered_incidents" :key="incident.id" class="incident">
                  <td>
                    <span>{{ incident.name.substring(0, 50) }}</span>
                  </td>
                  <td>{{ incident.location }}</td>
                  <td>{{ incident.date }}
                    {{ getFormattedTime(incident.time) }}
                    {{ getFormattedTimeZone(incident.time_zone) }}
                  </td>
                  <td>
                    <b-button class="btn-label" variant="info"
                              @click="launchFromPreplan(incident.id)">
                      <i class="bx bxs-rocket label-icon"></i>Launch from Pre-Plan
                    </b-button>
                  </td>
                </tr>
                </tbody>
                <!-- TODO Add pagination     -->
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>

</template>

<script>
import Layout from '@/router/layouts/main.vue';
import appConfig from '@/app.config.json';
import {
  authComputed,
  authMethods,
  incidentsComputed,
  incidentsMethods,
} from '@/state/helpers';
import timezoneDict from '@/components/timezoneDict';
import {
  closeLoading,
  showLoading,
} from '@/components/widgets/swalUtils';

export default {
  page: {
    title: 'Launch Incident',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
  },
  data() {
    return {
      incidents: null,
      incidents_count: null,
      filtered_incidents: null,
      searchInput: '',
      time_zones: timezoneDict,
      launchData: {
        name: '',
        location: '',
      },
    };
  },
  computed: {
    ...authComputed,
    ...incidentsComputed,
  },
  methods: {
    ...authMethods,
    ...incidentsMethods,
    goToIncidentDetails(incidentId) {
      this.$router.push({ name: 'Incident Details', params: { incidentId } });
    },
    launchFromPreplan(incident_id) {
      this.launchNewIncidentFromPreplan({
        user: JSON.parse(this.currentUser),
        incident_id,
      }).then((incidentId) => {
        showLoading('Loading incident...');
        this.setCurrentIncident(null);
        this.$router.push({ name: 'Incident Details', params: { incidentId } });
      }).catch(
        (error) => {
          console.error('Error: ', error);
        },
      );
    },
    launchFromData(launchData) {
      this.launchNewIncidentFromData({
        user: JSON.parse(this.currentUser),
        launchData,
      }).then((incidentId) => {
        showLoading('Loading incident...');
        this.setCurrentIncident(null);
        this.$router.push({ name: 'Incident Details', params: { incidentId } });
      }).catch(
        (error) => {
          console.error('Error: ', error);
        },
      );
    },
    filterIncidents() {
      if (this.searchInput === '') {
        this.filtered_incidents = this.incidents;
      } else {
        this.filtered_incidents = this.incidents.filter((incident) => incident.name.toLowerCase()
          .includes(this.searchInput.toLowerCase())
          || incident.location.toLowerCase().includes(this.searchInput.toLowerCase()));
      }
    },
    getFormattedTime(time) {
      return time ? time.replace(/:[0-9]\w$/g, '') : '';
    },
    getFormattedTimeZone(timezone) {
      if (timezone) {
        if (timezone.length > 5) {
          return this.time_zones[timezone];
        }
        return timezone;
      }
      return '';
    },
  },
  async mounted() {
    if (!this.getPreplanIncidents) {
      showLoading('Loading Preplan incidents...');
      await this.loadPreplanIncidents({
        user: JSON.parse(this.currentUser),
      });
      closeLoading();
    }
    this.incidents = this.getPreplanIncidents;
    this.incidents_count = this.incidents.length;
    this.filtered_incidents = this.incidents;
    // eslint-disable-next-line no-undef
    const autocomplete = new google.maps.places.Autocomplete(
      this.$refs.location,
    );
    // Autocomplete listener for location field
    autocomplete.addListener('place_changed', () => {
      const placeObject = autocomplete.getPlace();
      this.launchData.location = placeObject.formatted_address;
    });
  },
};
</script>

<style scoped>

.incident {
  cursor: pointer;
}

.launch-input {
  width: 500px;
}

.search-input {
  width: 400px;
}

</style>
